<template>
  <div class="breadcrumb">
    <el-button type="text" icon="jr-iconjr-icon-back" />
    <el-breadcrumb separator="/">
      <template v-for="(item, i) in levelList">
        <el-breadcrumb-item
          v-if="item.meta.title"
          :key="item.path"
          class="breadcrumb-item"
          :to="getPath(item.path)"
        >
          {{
            $route.params.paramsName && i === 2
              ? '' + $route.params.paramsName + ' ' + item.meta.title
              : item.meta.title
          }}
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>

    <div class="info">
      <span class="left_margin"
        >您好，贵阳电信<span class="user_cssname">{{ userName || "" }}</span> ，今天是{{
          time || ""
        }}，您是第<span class="user_cssname">{{ count || 1 }}</span
        >次登录智参~</span
      >
      <i class="iconfont icon-jr-icon-media"></i>
      <div class="avatar_box">
        <img src="../../../assets/image/corvor.png" alt="" />
      </div>
      <el-dropdown class="dropdown">
        <div class="root_name">
          {{ userName }}
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>个人中心</el-dropdown-item>
          <!-- <el-dropdown-item>修改密码</el-dropdown-item> -->
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
        <!-- <el-dropdown-menu>
          <el-dropdown-item>个人中心</el-dropdown-item>
          <el-dropdown-item>修改密码</el-dropdown-item>
        </el-dropdown-menu> -->
      </el-dropdown>
    </div>

    <!-- <div
      v-if="token"
      style="padding-right: 30px; margin-left: auto; cursor: pointer"
      @click="logout"
    >
      <span>退出登录</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',

  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      levelList: [],
      userName: sessionStorage.getItem('userName'),
      token: sessionStorage.getItem('token'),
      count: sessionStorage.getItem('count'),
      time:''
    }
  },

  computed: {
    getPath() {
      return function (path) {
        // if (path === '/businessData') return false

        let pathlocal = path

        if (pathlocal.indexOf(':') !== -1 && this.$route.params) {
          const paramsIndex = pathlocal.indexOf(':')

          const paramsStr = pathlocal.substring(paramsIndex).substring(1)

          pathlocal = pathlocal.substring(0, paramsIndex)

          pathlocal = pathlocal + this.$route.params[paramsStr]

          if (path !== pathlocal) {
            return pathlocal
          } else {
            return path
          }
        } else {
          return path
        }
      }
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
    this.getNowFormatDate()
  },
  methods: {
    getNowFormatDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0

      this.time = `${year}-${month}-${strDate}`;
    },
    // 切换侧边栏展开状态
    toggleCollapse() {
      this.$emit('update:isCollapse', !this.isCollapse)
    },
    // 动态面包屑
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name)

      this.levelList = matched
    },
    logout() {
      this.$confirm('您确定退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sessionStorage.removeItem('token')
          sessionStorage.removeItem('activePath')
          sessionStorage.removeItem('customerId')
          
          location.href = '/login'
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.breadcrumb {
  height: 60px;
  display: flex;
  align-items: center;
  .left_margin {
      margin-right: 20px;
      font-size: 16px;
    }
    .user_cssname {
      color: #448aff;
      font-size: 16px;
    }
  .el-breadcrumb {
    font-size: 16px;
    color: #252631;
  }
  .info {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 40px;
    .iconfont {
      margin-right: 20px;
      color: #333;
    }
    .avatar_box {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
      img {
        width: 36px;
        height: 36px;
      }
    }
    .root_name {
      cursor: pointer;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      margin-left: auto;
      i {
        margin-left: 10px;
        color: #333;
      }
    }
  }
}
</style>
