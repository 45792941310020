<template>
   <keep-alive include="case">
    <router-view  />
   </keep-alive>
   
</template>

<script>
export default {}
</script>

<style></style>
